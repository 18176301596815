import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import logo from 'ps-assets/img/mobile-all-policies-logo.png';
import bannerstar from 'ps-assets/img/star_health_mobile_banner.jpg';
import star from 'ps-assets/img/star.gif';
import submit from 'ps-assets/img/submit.gif';
import root from 'window-or-global';
import { landingPageLMSCall } from 'util/lmsRequest';
import MobileAllPoliciesStyle from 'ps-assets/jss/mobileAllPoliciesStyle.jsx';
import {  browserWindow } from "util/localStorageHelper";
const HOSPITALCOUNT = process.env.HOSPITALCOUNT;

const validationConditions = {
  name: `^[a-zA-Z .]*$`,
  phone: `^[6-9][0-9]{9}$`,
  email: `^[a-zA-Z0-9.!#$%&'*+/=?^_\`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$`,
  pincode: `[1-9][0-9]{5}$`,
};
class MobileAllPolicies extends React.Component {
  state = {
    check: false,
    user: {
      name: {
        mandatry: true,
        value: ``,
        error: true,
        errormsg: `Please Enter Name`,
        error1: `Please Enter Name`,
        error2: `Name should be minimum 3 and maximum 50 character`,
      },
      email: {
        mandatry: true,
        value: ``,
        error: true,
        errormsg: `Please enter correct Email ID and try again.`,
        error1: `Please enter correct Email ID and try again.`,
      },
      phone: {
        mandatry: true,
        value: ``,
        error: true,
        errormsg: `Please enter valid Phone Number`,
        error1: `Please enter valid Phone Number`,
        error2: `Phone Number should be minimum 10 Digits`,
      },

      pincode: {
        mandatry: true,
        value: ``,
        error: true,
        errormsg: `Please enter valid PinCode`,
        error1: `Please enter valid PinCode`,
        error2: `PinCode should be minimum 6 Digits`,
      },
    },
  };
  basestate = {
    user: this.state.user,
  };
  handleChange(e) {
    let change = {};
    change = JSON.parse(JSON.stringify(this.state.user));
    if (e.target.name == `name`) {
      if (change[e.target.name].mandatry == true) {
        if (e.target.value === ``) {
          change[e.target.name].errormsg = change[e.target.name].error1;
          change[e.target.name].error = true;
        } else if (e.target.value.length < 3) {
          change[e.target.name].errormsg = change[e.target.name].error2;
          change[e.target.name].error = true;
        } else {
          change[e.target.name].error = false;
        }
        let value = e.target.value.replace(/[^A-Z a-z]/gi, ``);
        change[e.target.name].value = value;
      }
    } else if (e.target.name == `email`) {
      if (change[e.target.name].mandatry == true) {
        if (e.target.value === ``) {
          change[e.target.name].errormsg = change[e.target.name].error1;
          change[e.target.name].error = true;
        } else if (
          e.target.value.search(validationConditions[e.target.name]) == -1
        ) {
          change[e.target.name].errormsg = change[e.target.name].error1;
          change[e.target.name].error = true;
        } else {
          change[e.target.name].error = false;
        }
        change[e.target.name].value = e.target.value;
      }
    } else if (e.target.name == `phone`) {
      if (change[e.target.name].mandatry == true) {
        if (e.target.value === ``) {
          change[e.target.name].errormsg = change[e.target.name].error1;
          change[e.target.name].error = true;
        } else if (
          e.target.value.search(validationConditions[e.target.name]) == -1
        ) {
          change[e.target.name].errormsg = change[e.target.name].error2;
          change[e.target.name].error = true;
        } else {
          change[e.target.name].error = false;
        }
        let value = e.target.value.replace(/[^0-9]/gi, ``);
        change[e.target.name].value = value;
      }
    } else if (e.target.name == `pincode`) {
      if (change[e.target.name].mandatry == true) {
        if (e.target.value === ``) {
          change[e.target.name].errormsg = change[e.target.name].error1;
          change[e.target.name].error = true;
        } else if (
          e.target.value.search(validationConditions[e.target.name]) == -1
        ) {
          if(e.target.value[0]=='0'){
            change[e.target.name].errormsg = change[e.target.name].error1;
            change[e.target.name].error = true;
          }else{
            change[e.target.name].errormsg = change[e.target.name].error2;
            change[e.target.name].error = true;
          }
          
        } else {
          change[e.target.name].error = false;
        }
        let value = e.target.value.replace(/[^0-9]/gi, ``);
        change[e.target.name].value = value;
      }
    }
    this.setState({
      user: change,
    });
  }
  handleCheck = () => {
    let check1 = !this.state.check;
    this.setState({
      check: check1,
    });
  };
  handleSubmit = async(event) => {
    let error = false;
    
    Object.keys(this.state.user).map((key) => {
      if (this.state.user[key].error && !error) {
        alert(this.state.user[key].errormsg);
        error = true;
        return;
      }
    });
    
    if (this.state.check == false) {
      alert(`Read Terms and Conditions`);
      error = true;
      return;
    }
   
    event.preventDefault();
    
    if (error == false) {
      let queryParams = decodeURIComponent(browserWindow.location.search);
        queryParams = queryParams.split(`?`)[1];
        let response = await landingPageLMSCall(
          this.state.user.name.value,
          this.state.user.phone.value,
          this.state.user.email.value,
          this.state.user.pincode.value
        );
        response = await response.json();
        sessionStorage.setItem(`prevLandingPage`,`free-health-quote`);
        root.open(`${browserWindow.location.pathname}/thank-you?userId=${response.zohoEntityId}${queryParams ? `&${queryParams}` : ``}`, `_self`);
    }
    
  };
  render() {
    const { classes } = this.props;
    let feature = [
      'Award of Health Insurance Company of the Year 2017 - Finteleket Insurance Awards 2017.',
      'Largest Network hospital base of  '+HOSPITALCOUNT+' hospitals',
      'Direct claim settlement without the intervention of TPA',
      '89.9% of all cashless claim approval done within 1hour.',
      'Awarded as the Best Health Insurance Provider of the year - Business Today, Money Today Financial Awards 2016-2017',
      'Pan India presence with more than 640+ Branch Offices',
    ];

    return (
      <>
        <div className={classes.pagedivstyle}>
          <div
            style={{
              boxShadow: '0 7px 7px -10px #000000',
              width: '100%',
            }}
          >
            <div>
              <div>
                <img
                  src={logo}
                  alt='cardiac-care-logo'
                  style={{ width: '123px', height: '59px' }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              background: `url(${bannerstar}) no-repeat #fff top center`,
              height: '292px',
            }}
          >
            <div className={classes.header1style}>
              <div>
                To be happy is to be healthy, your perfect health companion
              </div>
            </div>
          </div>
          <div className={classes.formdivstyle}>
          <form>
            <div className={classes.formheaderstyle}>
              Please share your details to request a call back
            </div>
            <div>
              <div className={classes.inputdivstyle}>
                <div className={classes.inputfieldstyle}> Name:</div>
                <div className={classes.inputdiv}>
                  <input
                    type='text'
                    className={classes.inputstyle}
                    name='name'
                    value={this.state.user.name.value}
                    onChange={this.handleChange.bind(this)}
                  />
                </div>
              </div>
              <div className={classes.inputdivstyle}>
                <div className={classes.inputfieldstyle}>Email id:</div>
                <div className={classes.inputdiv}>
                  <input
                    type='text'
                    className={classes.inputstyle}
                    name='email'
                    value={this.state.user.email.value}
                    onChange={this.handleChange.bind(this)}
                  />
                </div>
              </div>
              <div className={classes.inputdivstyle}>
                <div className={classes.inputfieldstyle}>Mobile No:</div>
                <div className={classes.inputdiv}>
                  <input
                    type='text'
                    className={classes.inputstyle}
                    name='phone'
                    value={this.state.user.phone.value}
                    onChange={this.handleChange.bind(this)}
                    maxLength='10'
                  />
                </div>
              </div>
              <div className={classes.inputdivstyle}>
                <div className={classes.inputfieldstyle}>PinCode:</div>
                <div className={classes.inputdiv}>
                  <input
                    type='text'
                    className={classes.inputstyle}
                    name='pincode'
                    value={this.state.user.pincode.value}
                    onChange={this.handleChange.bind(this)}
                    maxLength='6'
                  />
                </div>
              </div>
              <div style={{ fontSize: `12px` }}>
                <div className={classes.checkboxdivstyle}>
                  <input
                    type='checkbox'
                    name='checkbox'
                    title='Please accept terms and conditions'
                    id='checkbox'
                    onChange={this.handleCheck}
                    checked={this.state.check}
                  />
                  I have read and agree to the{' '}
                  <a
                    href='/static-privacy-policy'
                    style={{ color: `blue` }}
                    target='_blank'
                  >
                    <u>privacy policy</u>
                  </a>{' '}
                  I authorize Star Health And Allied Insurance to contact me via
                  email, phone or SMS.
                </div>
              </div>
              <div className={classes.sumbitdivstyle}>
                <img
                  src={submit}
                  onClick={this.handleSubmit}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </div>
            </form>
            <br />
          </div>
          <div className={classes.listheadstyle}>Why Starhealth?</div>
          <div className={classes.listdivstyle}>
            <ul className={classes.liststyle}>
              {feature.map((item, index) => (
                <li key={index}>
                  <img
                    src={star}
                    style={{ marginLeft: `-13px`, marginRight: `10px` }}
                  />
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className={classes.footerdivstyle}>
            <div style={{ fontSize: '12px' }}>
              <div>
                REGISTERED & CORPORATE OFFICE: NO: 1, NEW TANK STREET,
                VALLUVARKOTTAM HIGH ROAD, NUNGAMBAKKAM, CHENNAI - 600034. 
                Sales & Services - 044 4674 5800
              </div>
              <div style={{ marginTop: '-11px' }}>
                {' '}
                CIN NO: L66010TN2005PLC056649.
              </div>
              <div style={{ marginTop: '-11px' }}>
                {' '}
                IRDAI REGISTRATION NO: 129.
              </div>
              <div style={{ marginTop: '-11px' }}>
                {' '}
                INSURANCE IS THE SUBJECT MATTER OF SOLICITATION.
              </div>
              <div style={{ marginTop: '-11px' }}>
                {' '}
                FOR MORE DETAILS ON RISK FACTORS, TERMS AND CONDITIONS PLEASE
                READ THE SALES BROCHURE CAREFULLY BEFORE CONCLUDING A SALE.
              </div>
              <br />{' '}
              <div style={{ marginTop: '-15px' }}>
                IRDAI CLARIFIES TO PUBLIC THAT * IRDAI OR ITS OFFICIALS DO NOT
                INVOLVE IN ACTIVITIES LIKE SALE OF ANY KIND OF INSURANCE OR
                FINANCIAL PRODUCTS NOR INVEST PREMIUMS.{' '}
              </div>
              <div style={{ marginTop: '-11px' }}>
                * IRDAI DOES NOT ANNOUNCE ANY BONUS.
              </div>
              <div style={{ marginTop: '-11px' }}>
                {' '}
                * PUBLIC RECEIVING SUCH PHONE CALLS ARE REQUESTED TO LODGE A
                POLICE COMPLAINT ALONG WITH DETAILS OF PHONE CALL, NUMBER.
              </div>
              <br />
              <div style={{ marginTop: '-15px' }}>
                For further details visit{' '}
                <a href='https://www.starhealth.in/' style={{ color: 'white' }}>
                  www.starhealth.in{' '}
                </a>
                | SHAI/15-16/MOBILE/BRAND/001
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

MobileAllPolicies.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(MobileAllPoliciesStyle)(MobileAllPolicies);
