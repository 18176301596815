const MobileAllPoliciesStyle = function() {
  return {
    list: { width: '250' },
    fullList: { width: 'auto' },
    pagedivstyle: {
      background: 'white',
      height: '100%',
      width: '100%',
    },
    header1style: {
      width: '96%',
      fontSize: '22px',
      fontWeight: '800',
      color: '#1a4b9f',
      margin: '20px auto 6px auto',
      textAlign: 'center',
      paddingTop: '4% !important',
      marginTop: '-3% !important',
      "@media (max-width: 650px)": {
        textAlign:'center'
      }
    },
    header2style: {
      width: '96%',
      fontSize: '18px',
      fontWeight: '400',
      color: '#5c5c5c',
      margin: '0px auto',
      textAlign: 'left',
    },
    formdivstyle: {
      backgroundColor: '#becdd3',
      width: '100%',
      margin: '0px auto',
    },
    formheadstyle: {
      fontSize: '14px',
      color: '#1a4b9f',
      textAlign: 'center',
      fontWeight: '300',
      margin: '0px auto',
      padding: '10px 0px 10px 0px',
    },
    formheaderstyle: {
      fontSize: '14px',
      color: '#1a4b9f',
      textAlign: 'center',
      fontWeight: '300',
      margin: '0px auto',
      padding: '10px 0px 10px 0px',
    },
    inputdivstyle: {
      width: '95%',
      margin: '0px auto 10px auto',
      overflow: 'hidden',
    },
    inputfieldstyle: {
      fontSize: '12px',
      color: '#000000',
      fontWeight: '400',
      width: '34%',
      float: 'left',
      paddingTop: '3px',
      textAlign: 'right',
      "@media (max-width: 650px)": {
        width: '27%',
      }
    },
    inputdiv: {
      width: ' 65%',
      float: 'right',
      textAlign: 'left',
      marginBottom: '-4px',
      "@media (max-width: 650px)": {
        width: '70%',
      }
      
    },
    inputstyle: {
      background: ' #FFFFFF',
      width: '50%',
      height: '23px',
      paddingLeft: '1%',
      border: 'none',
      borderRadius: '5px 5px 10px 10px',
      "@media (max-width: 650px)": {
        width: '86%',
      }
    },
    checkboxdivstyle: {
      textAlign: 'center',
      fontSize: '10px',
      color: '#000000',
      fontWeight: '300',
      marginBottom: '10px',
      width: '98%',
      fontFamily: '`Roboto`, arial',
    },
    sumbitdivstyle: {
      width: '90%',
      margin: '0px auto 10px auto',
      overflow: 'hidden',
      textAlign: 'center',
    },
    listheadstyle: {
      fontFamily:`"Roboto", "Arail"`,
      color: '#007292',
      fontSize: '20px',
      fontWeight: '400',
      borderBottom: '2px solid #007292',
      padding: '6px 2%',
      textAlign: 'left',
    },
    listdivstyle: {
      width: '99%',
      margin: '0 auto',
      textAlign: 'left',
    },
    liststyle: {
      listStyle: 'none',
      lineHeight: '35px',
      fontSize: '12px',
      color: '#5e5f5f',
      fontWeight: 400,
      paddingLeft:'22px'
    },
    footerdivstyle: {
      fontSize: '10px',
      color: '#fff',
      fontWeight: '300',
      padding: '5px 0px',
      lineHeight:'24px',
      marginTop: '-10px',
      background: '#aeaeae',
      textAlign: 'center',
    },
  };
};

export default MobileAllPoliciesStyle;